import React, { ReactElement, memo, useCallback, useEffect, useRef, useState } from 'react';
import { slugify } from '@bridebook/toolbox/src';
import type { FelaCSS } from '@bridebook/ui/src/components/fela/flowtypes';
import Box from '../../../components/fela/Box';
import { TSpacingValue } from '../../../themes/types';
import componentStyles from './tabs-rounded.style';

export interface ITabsItem<T = string> {
  id: T;
  title: string | ReactElement;
  disabled?: boolean;
}

interface IProps<T> {
  tabs: ITabsItem<T>[];
  /**
   * Active tab is controlled by the parent
   */
  activeTabId: T;
  /**
   * Callback when a tab is clicked
   */
  onClick: (tabId: T) => void;
  /**
   * Size of the tabs
   */
  size?: 'default' | 'small';
  /**
   * Expand tabs to fill the width of the container.
   * If true, then tabs have equal widths.
   * If false, then tabs have variable width related to their content.
   */
  fullWidth?: boolean;
  /**
   * Padding of the tabs, only works if fullWidth is false, otherwise tabs have equal width
   */
  tabPadding?: TSpacingValue;
}

const TabsRoundedComp = <T,>({
  activeTabId,
  tabs,
  fullWidth = false,
  tabPadding = 6,
  onClick,
  size = 'default',
}: IProps<T>) => {
  const [sliderStyle, setSliderStyle] = useState<FelaCSS>({});
  const tabsRef = useRef<HTMLDivElement[]>([]);

  /**
   * Calculate the slider style based on the active tab width and position
   */
  const calculateSliderStyle = useCallback((el: HTMLDivElement) => {
    const rect = el.getBoundingClientRect();
    setSliderStyle({
      width: `${rect.width}px`,
      transform: `translateX(${el.offsetLeft}px)`,
      transition:
        'transform 0.5s cubic-bezier(0.34, 1.28, 0.64, 1), width 0.3s cubic-bezier(0.34, 1.28, 0.64, 1)',
    });
  }, []);

  /**
   * Calculate the slider style on mount and when the active tab changes size
   */
  useEffect(() => {
    const activeTabIndex = tabs.findIndex((tab) => tab.id === activeTabId);
    const activeTabElement = tabsRef.current[activeTabIndex];
    if (!activeTabElement) return;

    // Initial calculation
    calculateSliderStyle(activeTabElement);

    // Setup observer for subsequent changes
    const resizeObserver = new ResizeObserver(() => calculateSliderStyle(activeTabElement));
    resizeObserver.observe(activeTabElement);
    return () => resizeObserver.disconnect();
  }, [activeTabId, calculateSliderStyle, tabs]);

  const styles = componentStyles({ tabsAmount: tabs.length, fullWidth, size, tabPadding });

  return (
    <Box style={styles.wrapper}>
      <Box id="tabs-slider" style={{ ...styles.slider, ...sliderStyle }} />
      {tabs.map((item, index) => (
        <Box
          setRef={(el) => (tabsRef.current[index] = el)}
          key={`tabs-tab-item-${slugify(String(item.id))}`}
          as="a"
          id={`tab-${item.id}`}
          style={styles.tab({
            active: item.id === activeTabId,
            disabled: Boolean(item.disabled),
          })}
          onClick={() => !item.disabled && onClick(item.id)}>
          {item.title}
        </Box>
      ))}
    </Box>
  );
};

export const TabsRounded = memo(TabsRoundedComp) as typeof TabsRoundedComp;
