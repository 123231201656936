import type { TCheckboxSize } from 'src/components/bbcommon/inputs/checkbox-shell/checkbox-shell';
import { FelaCSS } from '../../../../components/fela/flowtypes';
import { colors, spacing } from '../../../../themes/variables';

interface PropTypes {
  disabled?: boolean;
  error?: boolean;
  checked: boolean;
  label?: boolean;
  doNotStyleCheckedLabel?: boolean;
  rounded?: boolean;
  alignCheckboxToTop?: boolean;
  variant: 'primary' | 'teal';
  size: TCheckboxSize;
}

interface StyleTypes {
  wrapper: FelaCSS;
  checkboxInput: FelaCSS;
  checkbox: FelaCSS;
  label: FelaCSS;
  iconWrap: FelaCSS;
  circle: FelaCSS;
}

const VARIANT_PALETTE = {
  primary: {
    border: colors.space40,
    background: colors.indigoCrush,
  },
  teal: {
    border: colors.space40,
    background: colors.teal,
  },
};

export const SIZES: Record<TCheckboxSize, number> = {
  default: 24,
  small: 20,
  tiny: 16,
};

const styles = ({
  disabled,
  error,
  label,
  doNotStyleCheckedLabel,
  checked,
  rounded,
  alignCheckboxToTop,
  variant,
  size,
}: PropTypes): StyleTypes => {
  const cursorStyle: FelaCSS = { cursor: disabled ? 'not-allowed' : 'pointer' };

  const roundedStyles = {
    checkbox: rounded
      ? {
          borderRadius: '50px',
          borderColor: disabled
            ? colors.space15
            : checked
              ? VARIANT_PALETTE[variant].background
              : VARIANT_PALETTE[variant].border,
          backgroundColor: 'transparent',
        }
      : {},
  };

  const inputBoxAlignment: FelaCSS = alignCheckboxToTop
    ? {
        top: '0',
      }
    : {
        top: '50%',
        transform: 'translateY(-50%)',
      };

  return {
    wrapper: {
      paddingLeft: `${SIZES[size]}px`,
    },

    checkboxInput: {
      width: SIZES[size],
      height: SIZES[size],
      marginTop: '0 !important',
      marginBottom: '0',
      marginLeft: '0',
      marginRight: '0',
      position: 'absolute',
      left: '0',
      top: alignCheckboxToTop ? '0' : '50%',
      transform: alignCheckboxToTop ? undefined : 'translateY(-50%)',
      opacity: 0,
      zIndex: 1,
      cursor: disabled ? 'not-allowed' : 'pointer',
      paddingTop: '0',
      paddingRight: '0',
      paddingBottom: '0',
      paddingLeft: '0',
    },

    checkbox: {
      ...inputBoxAlignment,
      boxSizing: 'border-box',
      position: 'absolute',
      left: '0',
      width: SIZES[size],
      height: SIZES[size],
      borderWidth: size === 'default' ? 2 : 1,
      borderRadius: size === 'default' ? 6 : 4,
      borderStyle: 'solid',
      borderColor: checked
        ? VARIANT_PALETTE[variant].background
        : error
          ? colors.blushTangerine120
          : VARIANT_PALETTE[variant].border,
      backgroundColor: checked
        ? VARIANT_PALETTE[variant].background
        : disabled
          ? colors.space15
          : colors.white,
      ...cursorStyle,
      ...roundedStyles.checkbox,
    },

    label: {
      display: 'inline-flex',
      justifyContent: 'center',
      paddingLeft: label ? spacing[2] : '0',
      minHeight: SIZES[size],
      cursor: 'pointer',
      opacity: disabled ? 0.65 : 1,
      fontDefaultSemiBold: 14,
      color: checked && !doNotStyleCheckedLabel ? colors.space : colors.space60,
      marginBottom: 0,
      ...cursorStyle,
    },

    circle: checked
      ? {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: disabled ? colors.space15 : colors.indigoCrush,
          borderRadius: '50px',
          width: (SIZES[size] * 2) / 3,
          height: (SIZES[size] * 2) / 3,
          boxSizing: 'border-box',
        }
      : {},

    iconWrap: {
      display: checked ? 'flex' : 'none',
      width: '100%',
      height: '100%',
      lineHeight: '20px',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      left: '0',
      top: '0',
    },
  };
};

export default styles;
